import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Text,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Icon,
  Stack,
  FormControl,
  Input,
  FormLabel,
  useToast,
  Divider,
  Avatar,
  FormErrorMessage,
  Switch,
} from "@chakra-ui/react";

import firebase from "../utils/firebase";
import "firebase/firestore";
import {
  useCollectionData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";

import DataTable from "../utils/table";

import { FaArrowDown, FaArrowUp, FaInfo, FaSearch, FaTv } from "react-icons/fa";
import { useAuth } from "../components/auth/useAuth";
import { Formik, Form, Field } from "formik";
import { LoadingCard, ErrorCard } from "../utils/helpers";
import { Link } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../utils/styles.css";

export default function Lines(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openModal, setOpenModal] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [search, setSearch] = useState<any>(null);

  const [direction, setDirection] = useState<any>("desc");
  const [closeView, setCloseView] = useState<any>(false);

  const [order, setOrder] = useState("creation");
  const pageLimit = 12;
  const { signOut } = useAuth();
  const toast = useToast();

  let usersRef;
  if (search && search.result && search.field) {
    usersRef = firebase
      .firestore()
      .collection("accounts")
      .where(search.field, "==", search.result);
  } else if (search && search.pagination === "next") {
    usersRef = firebase
      .firestore()
      .collection("accounts")
      .orderBy(order, direction)
      .startAfter(search.lastVisible)
      .limit(pageLimit);
  } else if (search && search.pagination === "back") {
    usersRef = firebase
      .firestore()
      .collection("accounts")
      .orderBy(order, direction)
      .startAt(search.back)
      .limit(pageLimit);
  } else {
    usersRef = firebase
      .firestore()
      .collection("accounts")
      .orderBy(order, direction)
      .limit(pageLimit);
  }

  const [values, loading, error] = useCollectionData(usersRef, {
    idField: "uid",
    refField: "ref",
  });

  const resetPendingCountry = async () => {
    await firebase
      .firestore()
      .collection("users")
      .where("country_reset", "==", "pending")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update({
            country_reset: "active",
            last_country_reset: firebase.firestore.FieldValue.delete(),
          });
        });
        toast({
          title:
            querySnapshot.size === 0
              ? "No pending requests!"
              : "Success country reset has been reset👍",
          status: querySnapshot.size === 0 ? "info" : "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "An error occurred. Please try again later!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      w={"full"}
      minH={"100vh"}
      flexDir={"column"}
      bg={"gray.800"}
      overflow={"auto"}
    >
      <Flex
        color={"white"}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={"gray.900"}
        align={"center"}
        shadow={"md"}
      >
        <Flex flex={{ base: 1 }} justify="start">
          <Avatar
            src={"./images/zebra.png"}
            mr={2}
            p={1}
            bg={"white"}
            as={Link}
            to={"/admin"}
          />
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Button fontSize={"sm"} variant={"redBtn"} onClick={() => signOut()}>
            Sign Out
          </Button>
        </Stack>
      </Flex>
      <Flex
        flexDir={"column"}
        px={{ base: 2, sm: 5 }}
        pt={10}
        h={"full"}
        mb={"5vh"}
      >
        <Box w={"full"} bg={"gray.700"} rounded={"lg"} boxShadow={"md"} p={6}>
          <Flex
            align={"center"}
            w={"full"}
            justify={"center"}
            flexDir={{ base: "column", md: "row" }}
          >
            <SearchBar setSearch={setSearch} />
            <FilterUI
              order={order}
              direction={direction}
              setDirection={setDirection}
              setOrder={setOrder}
              setSearch={setSearch}
            />
            {/* <Button
              onClick={async () => {
                await resetPendingCountry();
              }}
              variant={"redBtn"}
              ml={3}
            >
              Reset Pending
            </Button> */}
          </Flex>
          <Flex
            mt={3}
            align={"center"}
            w={"full"}
            justify={"center"}
            flexDir={{ base: "column", md: "row" }}
          >
            <Button
              onClick={() => {
                setOpenModal("NewModal");
                onOpen();
              }}
              variant={"redBtn"}
              ml={5}
            >
              Add
            </Button>
            {/* <Button
              onClick={() => {
                setOpenModal("MoveLine");
                onOpen();
              }}
              variant={"redBtn"}
              ml={3}
            >
              Move
            </Button> */}
            {/* <Button
              variant={"redBtn"}
              onClick={() => setCloseView(true)}
              ml={3}
            >
              OTT Stats
            </Button> */}
          </Flex>
        </Box>
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
        >
          {values && (
            <DataTable
              columns={columnHeaders(setOpenModal, setCurrentUser, onOpen)}
              data={values}
            />
          )}
          <Flex justifyContent={"center"} mt={6}>
            {values && search && search.counter ? (
              <Button
                mx={2}
                onClick={() => {
                  setSearch({
                    lastVisible: values.length
                      ? values[values.length - 1][order]
                      : search.firstVisible[search.counter - 1],
                    firstVisible: search.firstVisible,
                    back: search.firstVisible[search.counter - 1],
                    pagination: "back",
                    counter: search.counter - 1,
                  });
                }}
              >
                Back
              </Button>
            ) : (
              <></>
            )}
            {values && values.length > 0 && (
              <Button
                mx={2}
                onClick={() => {
                  setSearch({
                    lastVisible: values[values.length - 1][order],
                    firstVisible: search?.firstVisible
                      ? [...search?.firstVisible, values[0][order]]
                      : [values[0][order]],
                    pagination: "next",
                    counter: search?.counter ? search.counter + 1 : 1,
                  });
                }}
              >
                Next
              </Button>
            )}
          </Flex>
          {loading && <LoadingCard />}
          {error && <ErrorCard />}
          {error && <Text>{JSON.stringify(error.message)}</Text>}
          {openModal === "Details" && currentUser && (
            <ViewDetails data={currentUser} onClose={onClose} isOpen={isOpen} />
          )}
          {openModal === "NewLine" && (
            <NewLine onClose={onClose} isOpen={isOpen} />
          )}
          {openModal === "FreeLine" && (
            <FreeLine onClose={onClose} isOpen={isOpen} />
          )}
          {openModal === "NewModal" && (
            <NewModal
              setOpenModal={setOpenModal}
              onClose={onClose}
              isOpen={isOpen}
            />
          )}
          {openModal === "MoveLine" && (
            <MoveLine onClose={onClose} isOpen={isOpen} />
          )}
        </Box>
        {closeView && <QuickView closeView={setCloseView} />}
      </Flex>
    </Flex>
  );
}

const QuickView = ({ closeView }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, []);

  const closeModal = () => {
    onClose();
    closeView(false);
  };

  const query = firebase
    .firestore()
    .collection("accounts")
    .where("status", "==", "free")
    .where("provider", "==", "OTT");
  const [values2] = useCollectionData(query);

  const query2 = firebase
    .firestore()
    .collection("accounts")
    .where("status", "==", "taken")
    .where("provider", "==", "OTT");
  const [values4] = useCollectionData(query2);

  return (
    <Modal onClose={closeModal} isOpen={isOpen} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaTv} mr={2} />
          OTT Stats
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            my={4}
            align={"center"}
            w={"full"}
            justify={"center"}
            flexDir={"column"}
          >
            <Text color={"pink.400"} fontSize={"lg"}>
              USED OTT: {values4?.length}
            </Text>
            <Text mt={5} color={"pink.400"} fontSize={"lg"}>
              Free OTT: {values2?.length}
            </Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const columnHeaders = (setOpenModal: any, setCurrentUser: any, onOpen: any) => [
  {
    Header: "Username",
    Cell: (row: any) => {
      const data = row.row.original;
      return <Text>{data.username}</Text>;
    },
  },
  {
    Header: "Password",
    Cell: (row: any) => {
      const data = row.row.original;
      return <Text>{data.password}</Text>;
    },
  },
  {
    Header: "Status",
    Cell: (row: any) => {
      const data = row.row.original;
      return (
        <Text
          color={
            data.status === "free"
              ? "green.500"
              : data.status === "taken"
              ? "red.500"
              : "orange.500"
          }
        >
          {data.status === "free"
            ? "Free"
            : data.status === "taken"
            ? "Taken"
            : data.status}
        </Text>
      );
    },
  },
  {
    Header: "Provider",
    Cell: (row: any) => {
      const prov = row.row.original.provider;
      if (prov === "MEGA") {
        return <Text color={"blue.400"}>MEGA</Text>;
      } else if (prov === "OTT") {
        return <Text color={"pink.400"}>OTT</Text>;
      } else if (prov === "MEGA2") {
        return <Text color={"yellow.400"}>MEGA2</Text>;
      }
      return row.row.original.provider;
    },
  },
  {
    Header: "Expiration",
    Cell: (row: any) => {
      const data = row.row.original;
      if (data.expiration && data.expiration.seconds)
        return (
          <Text>{new Date(data.expiration.seconds * 1000).toDateString()}</Text>
        );
      else return "-";
    },
  },
  {
    Header: "Actions",
    Cell: (row: any) => {
      const data = row.row.original;
      if (!data.user) data.user = "nothing";
      return (
        <Flex>
          <IconButton
            icon={<FaInfo />}
            aria-label="Details"
            mr={1}
            onClick={() => {
              setOpenModal("Details");
              setCurrentUser(data);
              onOpen();
            }}
          />
        </Flex>
      );
    },
  },
];

function ViewDetails({ data, onClose, isOpen }: any) {
  const resellerRef = firebase.firestore().collection("users").doc(data?.user);

  const [value, loading, error] = useDocumentDataOnce(resellerRef, {
    idField: "uid",
  });

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaTv} mr={2} />
          Line Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {data && !loading && !error && (
            <Flex flexDir={"column"}>
              <Stack spacing={4}>
                {value && data.user && (
                  <FormControl>
                    <FormLabel>User</FormLabel>
                    <Input
                      isReadOnly
                      defaultValue={value?.email}
                      variant={"redInput"}
                    />
                  </FormControl>
                )}
                <FormControl>
                  <FormLabel>ID</FormLabel>
                  <Input
                    isReadOnly
                    defaultValue={data.id}
                    variant={"redInput"}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Username</FormLabel>
                  <Input
                    isReadOnly
                    defaultValue={data.username}
                    variant={"redInput"}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input
                    isReadOnly
                    defaultValue={data.password}
                    variant={"redInput"}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Creation</FormLabel>
                  <Input
                    isReadOnly
                    defaultValue={new Date(
                      data.creation.seconds * 1000
                    ).toDateString()}
                    variant={"redInput"}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Expiration</FormLabel>
                  <Input
                    isReadOnly
                    defaultValue={new Date(
                      data.expiration.seconds * 1000
                    ).toDateString()}
                    variant={"redInput"}
                  />
                </FormControl>
                <Text fontSize={"sm"} textAlign={"center"}>
                  {data.uid}
                </Text>
              </Stack>
              <Button isFullWidth onClick={onClose} variant="redBtn" my={6}>
                Return
              </Button>
            </Flex>
          )}
          {loading && <LoadingCard />}
          {error && <ErrorCard />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function SearchBar(props: any) {
  return (
    <Formik
      initialValues={{ search: "" }}
      onSubmit={async (values: any, actions: any) => {
        props.setSearch({ field: "username", result: values.search });
        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack
            spacing={1}
            direction={{ base: "column", md: "row" }}
            alignItems={"center"}
          >
            <Stack direction={"row"}>
              <Field name="search">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.search && form.touched.search}
                  >
                    <Input
                      {...field}
                      id="search"
                      placeholder="Search"
                      w={{ base: "full", md: "25vw" }}
                      variant={"redInput"}
                    />
                  </FormControl>
                )}
              </Field>
              <Button isLoading={isSubmitting} type="submit">
                <Icon as={FaSearch} />
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

function FilterUI(props: any) {
  const buttons = [
    {
      filter: "username",
      name: "Username",
    },
    {
      filter: "creation",
      name: "Created",
    },
    {
      filter: "expiration",
      name: "Expiration ",
    },
    {
      filter: "status",
      name: "Status",
    },
    {
      filter: "provider",
      name: "Provider",
    },
  ];

  return (
    <Flex ml={{ base: 0, md: 2 }} flexDir={"row"} flexWrap={"wrap"}>
      <Divider my={4} display={{ base: "block", md: "none" }} />
      {buttons.map((item, index) => (
        <Button
          my={{ base: 1, md: 0 }}
          key={index}
          mx={1}
          onClick={() => {
            props.setSearch(null);
            props.setDirection(
              item.filter !== props.order
                ? "asc"
                : props.direction === "desc"
                ? "asc"
                : "desc"
            );
            props.setOrder(item.filter);
          }}
        >
          {item.name}
          {props.order === item.filter && props.direction === "asc" && (
            <Icon as={FaArrowUp} ml={1} />
          )}
          {props.order === item.filter && props.direction === "desc" && (
            <Icon as={FaArrowDown} ml={1} />
          )}
        </Button>
      ))}
    </Flex>
  );
}

function NewModal({ setOpenModal, onClose, isOpen }: any) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"3xl"}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaTv} mr={2} />
          Add Line MEGA2
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={8} direction={"column"} m={4}>
            <Stack spacing={4} display="flex" align="center">
              <Text fontSize="lg" fontWeight="bold">
                Assign line to a user
              </Text>
              <Text textAlign={"center"}>
                Add a new line to a user. Use this when a user is stuck on
                Pending or when you want to give a user a new line
              </Text>
              <Button
                w="full"
                onClick={() => setOpenModal("NewLine")}
                variant="redBtn"
                mt="auto"
              >
                Add
              </Button>
            </Stack>
            <Divider />
            <Stack spacing={4} display="flex" align="center">
              <Text fontSize="lg" fontWeight="bold">
                Free Line
              </Text>
              <Text textAlign={"center"}>
                Add a new free line. Use this when you free up a line on MEGA2
                and you want it to be available to new users.
              </Text>
              <Button
                w="full"
                onClick={() => setOpenModal("FreeLine")}
                variant="redBtn"
                mt="auto"
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function NewLine({ onClose, isOpen }: any) {
  const toast = useToast();
  function validateRequired(value: any) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={"lg"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaTv} mr={2} />
          New Line
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              id: "",
              password: "",
              userUID: "",
              username: "",
              creation: "",
              expiration: "",
              subscriptionID: "",
            }}
            onSubmit={async (values: any, actions: any) => {
              if (values.username !== values.username.toLowerCase()) {
                toast({
                  title:
                    "The username needs to be lowercased. Please change it on MEGA first.",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
              } else {
                if (values.creation && values.expiration) {
                  await firebase
                    .firestore()
                    .collection("users")
                    .doc(values.userUID)
                    .get()
                    .then(async (user) => {
                      if (user.exists) {
                        await firebase
                          .firestore()
                          .collection("accounts")
                          .add({
                            id: values.id,
                            password: values.password,
                            status: "taken",
                            user: values.userUID,
                            username: values.username,
                            creation: firebase.firestore.Timestamp.fromDate(
                              new Date(values.creation)
                            ),
                            expiration: firebase.firestore.Timestamp.fromDate(
                              new Date(values.expiration)
                            ),
                            provider: "MEGA2",
                          })
                          .then(() =>
                            user.ref
                              .update({
                                subscription: "active",
                                subscriptionID: values.subscriptionID,
                              })
                              .then(() => {
                                toast({
                                  title: "Success lines created and assigned👍",
                                  status: "success",
                                  duration: 9000,
                                  isClosable: true,
                                });
                                actions.setSubmitting(false);
                                onClose();
                              })
                          );
                      } else {
                        toast({
                          title: "User does not exist",
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        });
                        actions.setSubmitting(false);
                      }
                    })
                    .catch((err) => {
                      toast({
                        title: "Error occurred 😭",
                        description: err.message,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                      actions.setSubmitting(false);
                    });
                } else {
                  toast({
                    title: "Fields required",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                  actions.setSubmitting(false);
                }
              }
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <Stack spacing={4}>
                  <Box
                    border="1px"
                    borderColor={"inherit"}
                    padding={3}
                    borderRadius={"md"}
                  >
                    <Text
                      textAlign={"center"}
                      color={"red.500"}
                      fontWeight={"600"}
                    >
                      Line Details
                    </Text>
                    <Stack spacing={4}>
                      <Field name="id" validate={validateRequired}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.id && form.touched.id}
                          >
                            <FormLabel htmlFor="expiration">ID</FormLabel>
                            <Input
                              {...field}
                              id="id"
                              placeholder="Get from insect (MEGA2 Panel)"
                              variant={"redInput"}
                            />
                            <FormErrorMessage>
                              {form.errors.id}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="username" validate={validateRequired}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.username && form.touched.username
                            }
                          >
                            <FormLabel htmlFor="expiration">Username</FormLabel>
                            <Input
                              {...field}
                              id="username"
                              placeholder="Username"
                              variant={"redInput"}
                            />
                            <FormErrorMessage>
                              {form.errors.username}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="password" validate={validateRequired}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                          >
                            <FormLabel htmlFor="expiration">Password</FormLabel>
                            <Input
                              {...field}
                              id="password"
                              placeholder="Password"
                              variant={"redInput"}
                            />
                            <FormErrorMessage>
                              {form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="creation">
                        {({ field, form }: any) => (
                          <FormControl>
                            <FormLabel htmlFor="creation">
                              Creation Date
                            </FormLabel>
                            <DatePicker
                              minDate={new Date()}
                              showPopperArrow={false}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              onChange={(d: any) => {
                                setFieldValue("creation", d);
                              }}
                              selected={field.value}
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field name="expiration">
                        {({ field, form }: any) => (
                          <FormControl>
                            <FormLabel htmlFor="expiration">
                              Expiration Date
                            </FormLabel>
                            <DatePicker
                              minDate={new Date()}
                              showPopperArrow={false}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              onChange={(d: any) => {
                                setFieldValue("expiration", d);
                              }}
                              selected={field.value}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                  </Box>
                  <Field name="userUID" validate={validateRequired}>
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.userUID && form.touched.userUID}
                      >
                        <FormLabel htmlFor="expiration">
                          User UID (Panel)
                        </FormLabel>
                        <Input
                          {...field}
                          id="userUID"
                          placeholder="Get from users page (Admin Panel)"
                          variant={"redInput"}
                        />
                        <FormErrorMessage>
                          {form.errors.userUID}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="subscriptionID" validate={validateRequired}>
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.subscriptionID &&
                          form.touched.subscriptionID
                        }
                      >
                        <FormLabel htmlFor="expiration">
                          Subscription ID (Stripe)
                        </FormLabel>
                        <Input
                          {...field}
                          id="subscriptionID"
                          placeholder="Get from Stripe user's subscription (sub_)"
                          variant={"redInput"}
                        />
                        <FormErrorMessage>
                          {form.errors.subscriptionID}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Button
                  isFullWidth
                  isLoading={isSubmitting}
                  type="submit"
                  variant={"redBtn"}
                  my={6}
                >
                  Add
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function FreeLine({ onClose, isOpen }: any) {
  const toast = useToast();
  const { user } = useAuth();

  function validateRequired(value: any) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={"lg"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaTv} mr={2} />
          Add Free Line
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              id: "",
              username: "",
              password: "",
              creation: "",
              expiration: "",
              changeUname: true,
            }}
            onSubmit={async (values: any, actions: any) => {
              if (
                values.username !== values.username.toLowerCase() &&
                !values.changeUname
              ) {
                toast({
                  title:
                    "The username needs to be lowercased, change it on MEGA first. OR select the change username option.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
              } else {
                if (values.creation && values.expiration) {
                  await firebase
                    .firestore()
                    .collection("accounts")
                    .where("id", "==", values.id)
                    .get()
                    .then(async (querySnapshot) => {
                      if (querySnapshot.size > 0) {
                        toast({
                          title: "Line already exists",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                        actions.setSubmitting(false);
                      } else {
                        await firebase
                          .firestore()
                          .collection("accounts")
                          .add({
                            id: values.id,
                            password: values.password,
                            status: values.changeUname ? "editing" : "free",
                            username: values.username,
                            creation: firebase.firestore.Timestamp.fromDate(
                              new Date(values.creation)
                            ),
                            expiration: firebase.firestore.Timestamp.fromDate(
                              new Date(values.expiration)
                            ),
                            provider: "MEGA2",
                          })
                          .then((docRef) => {
                            if (values.changeUname) {
                              fetch(
                                process.env.REACT_APP_API_URL + "/freeLine",
                                {
                                  method: "POST",
                                  mode: "cors",
                                  body: JSON.stringify({
                                    lineID: values.id,
                                    accountID: docRef.id,
                                    uid: user.uid,
                                  }),
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                                .then((res) => res.json())
                                .then(
                                  async (res) => {
                                    if (res.status === 1) {
                                      toast({
                                        title: values.changeUname
                                          ? "Lines added successfully. Allow 5 minutes for the username to change 👍"
                                          : "Line added successfully.",
                                        status: "success",
                                        duration: 5000,
                                        isClosable: true,
                                      });
                                      actions.setSubmitting(false);
                                      onClose();
                                    } else {
                                      toast({
                                        title: "Error adding the line",
                                        status: "error",
                                        duration: 5000,
                                        isClosable: true,
                                      });
                                      actions.setSubmitting(false);
                                    }
                                  },
                                  (error) => {
                                    toast({
                                      title: "Error adding the line",
                                      status: "error",
                                      duration: 5000,
                                      isClosable: true,
                                    });
                                  }
                                );
                            } else {
                              toast({
                                title: "Line added successfully.",
                                status: "success",
                                duration: 5000,
                                isClosable: true,
                              });
                              actions.setSubmitting(false);
                              onClose();
                            }
                          });
                      }
                    })
                    .catch((err) => {
                      toast({
                        title: "Error occurred 😭",
                        description: err.message,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                      actions.setSubmitting(false);
                    });
                } else {
                  toast({
                    title: "Fields required",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                  actions.setSubmitting(false);
                }
              }
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <Stack spacing={4}>
                  <Box
                    border="1px"
                    borderColor={"inherit"}
                    padding={3}
                    borderRadius={"md"}
                  >
                    <Text
                      textAlign={"center"}
                      color={"red.500"}
                      fontWeight={"600"}
                    >
                      Line Details
                    </Text>
                    <Stack spacing={4}>
                      <Field name="id" validate={validateRequired}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.id && form.touched.id}
                          >
                            <FormLabel htmlFor="expiration">ID</FormLabel>
                            <Input
                              {...field}
                              id="id"
                              placeholder="Get from insect (MEGA2 Panel)"
                              variant={"redInput"}
                            />
                            <FormErrorMessage>
                              {form.errors.id}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="username" validate={validateRequired}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.username && form.touched.username
                            }
                          >
                            <FormLabel htmlFor="expiration">Username</FormLabel>
                            <Input
                              {...field}
                              id="username"
                              placeholder="Username"
                              variant={"redInput"}
                            />
                            <FormErrorMessage>
                              {form.errors.username}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="password" validate={validateRequired}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                          >
                            <FormLabel htmlFor="expiration">Password</FormLabel>
                            <Input
                              {...field}
                              id="password"
                              placeholder="Password"
                              variant={"redInput"}
                            />
                            <FormErrorMessage>
                              {form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="creation">
                        {({ field, form }: any) => (
                          <FormControl>
                            <FormLabel htmlFor="creation">
                              Creation Date
                            </FormLabel>
                            <DatePicker
                              showPopperArrow={false}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              onChange={(d: any) => {
                                setFieldValue("creation", d);
                              }}
                              selected={field.value}
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field name="expiration">
                        {({ field, form }: any) => (
                          <FormControl>
                            <FormLabel htmlFor="expiration">
                              Expiration Date
                            </FormLabel>
                            <DatePicker
                              minDate={new Date()}
                              showPopperArrow={false}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              onChange={(d: any) => {
                                setFieldValue("expiration", d);
                              }}
                              selected={field.value}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                  </Box>
                  <Field name="changeUname">
                    {({ field, form }: any) => (
                      <FormControl>
                        <Stack direction="row" align="center">
                          <FormLabel htmlFor="changeUname">
                            Change Username
                          </FormLabel>
                          <Switch
                            sx={{
                              "span.chakra-switch__track:is([data-checked])": {
                                backgroundColor: "red.500",
                              },
                            }}
                            {...field}
                            id="changeUname"
                            size="lg"
                            defaultChecked={field.value}
                          />
                        </Stack>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Button
                  isFullWidth
                  isLoading={isSubmitting}
                  type="submit"
                  variant={"redBtn"}
                  my={6}
                >
                  Add
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function MoveLine({ data, onClose, isOpen }: any) {
  const toast = useToast();
  const { user } = useAuth();
  function validateRequired(value: any) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={"lg"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaTv} mr={2} />
          Move Line
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              id: "",
              password: "",
              oldLineID: "",
              username: "",
              creation: "",
              expiration: "",
            }}
            onSubmit={async (values: any, actions: any) => {
              if (values.username !== values.username.toLowerCase()) {
                toast({
                  title:
                    "The username needs to be lowercased. Please change it on MEGA first.",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
                actions.setSubmitting(false);
              } else {
                if (values.creation && values.expiration) {
                  await firebase
                    .firestore()
                    .collection("accounts")
                    .doc(values.oldLineID)
                    .get()
                    .then(async (line) => {
                      if (line.exists && line.data()) {
                        const lineData = line.data();
                        await firebase
                          .firestore()
                          .collection("accounts")
                          .add({
                            id: values.id,
                            password: values.password,
                            status: "taken",
                            user: lineData?.user,
                            username: values.username,
                            creation: firebase.firestore.Timestamp.fromDate(
                              new Date(values.creation)
                            ),
                            expiration: firebase.firestore.Timestamp.fromDate(
                              new Date(values.expiration)
                            ),
                            provider: "MEGA2",
                          })
                          .then(() =>
                            line.ref
                              .update({
                                status: "updating",
                                user: firebase.firestore.FieldValue.delete(),
                              })
                              .then(async () => {
                                await firebase
                                  .firestore()
                                  .collection("users")
                                  .doc(lineData?.user)
                                  .update({
                                    provider: "MEGA2",
                                  });

                                fetch(
                                  process.env.REACT_APP_API_URL +
                                    "/unassignOTTLine",
                                  {
                                    method: "POST",
                                    mode: "cors",
                                    body: JSON.stringify({
                                      uid: user.uid,
                                      accountID: values.oldLineID,
                                      lineID: lineData?.id,
                                    }),
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                  }
                                );
                                toast({
                                  title: "Success line created and moved👍",
                                  status: "success",
                                  duration: 9000,
                                  isClosable: true,
                                });
                                actions.setSubmitting(false);
                                onClose();
                              })
                          );
                      } else {
                        toast({
                          title: "Line does not exist",
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        });
                        actions.setSubmitting(false);
                      }
                    })
                    .catch((err) => {
                      toast({
                        title: "Error occurred 😭",
                        description: err.message,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                      actions.setSubmitting(false);
                    });
                } else {
                  toast({
                    title: "Fields required",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                  actions.setSubmitting(false);
                }
              }
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <Stack spacing={4}>
                  <Box
                    border="1px"
                    borderColor={"inherit"}
                    padding={3}
                    borderRadius={"md"}
                  >
                    <Text
                      textAlign={"center"}
                      color={"red.500"}
                      fontWeight={"600"}
                    >
                      Line Details
                    </Text>
                    <Stack spacing={4}>
                      <Field name="id" validate={validateRequired}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.id && form.touched.id}
                          >
                            <FormLabel htmlFor="expiration">ID</FormLabel>
                            <Input
                              {...field}
                              id="id"
                              placeholder="Get from the URL (MEGA2 Panel)"
                              variant={"redInput"}
                            />
                            <FormErrorMessage>
                              {form.errors.id}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="username" validate={validateRequired}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.username && form.touched.username
                            }
                          >
                            <FormLabel htmlFor="expiration">Username</FormLabel>
                            <Input
                              {...field}
                              id="username"
                              placeholder="Username"
                              variant={"redInput"}
                            />
                            <FormErrorMessage>
                              {form.errors.username}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="password" validate={validateRequired}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                          >
                            <FormLabel htmlFor="expiration">Password</FormLabel>
                            <Input
                              {...field}
                              id="password"
                              placeholder="Password"
                              variant={"redInput"}
                            />
                            <FormErrorMessage>
                              {form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="creation">
                        {({ field, form }: any) => (
                          <FormControl>
                            <FormLabel htmlFor="creation">
                              Creation Date
                            </FormLabel>
                            <DatePicker
                              showPopperArrow={false}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              onChange={(d: any) => {
                                setFieldValue("creation", d);
                              }}
                              selected={field.value}
                            />
                          </FormControl>
                        )}
                      </Field>
                      <Field name="expiration">
                        {({ field, form }: any) => (
                          <FormControl>
                            <FormLabel htmlFor="expiration">
                              Expiration Date
                            </FormLabel>
                            <DatePicker
                              minDate={new Date()}
                              showPopperArrow={false}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              onChange={(d: any) => {
                                setFieldValue("expiration", d);
                              }}
                              selected={field.value}
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                  </Box>
                  <Field name="oldLineID" validate={validateRequired}>
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.oldLineID && form.touched.oldLineID
                        }
                      >
                        <FormLabel htmlFor="oldLineID">
                          OLD LINE ID (Panel)
                        </FormLabel>
                        <Input
                          {...field}
                          id="oldLineID"
                          placeholder="Get from line details (Admin Panel)"
                          variant={"redInput"}
                        />
                        <FormErrorMessage>
                          {form.errors.oldLineID}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
                <Button
                  isFullWidth
                  isLoading={isSubmitting}
                  type="submit"
                  variant={"redBtn"}
                  my={6}
                >
                  Add
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
