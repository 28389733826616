import React, { useState, useEffect, useContext, createContext } from "react";
import { useHistory } from "react-router-dom";

import firebase from "../../utils/firebase";
import "firebase/auth";
import "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function ProvideAuth({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const EmailAuth = async (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (response) => {
        await checkAdmin();
        setUser(response.user);
        history.push("/admin");
      });
  };

  const signOut = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const checkAdmin = async (usr) => {
    return firebase
      .firestore()
      .doc("settings/admin")
      .get()
      .then((doc) => {
        if (!doc.data().status) {
          throw Object.assign(new Error("unavailable_login"), {
            code: "unavailable_login",
          });
        }
      })
      .catch((e) => {
        console.log(e.message);
        signOut();
        throw Object.assign(
          new Error(
            e.message === "unavailable_login"
              ? "unavailable_login"
              : "unauthorized"
          ),
          {
            code:
              e.message === "unavailable_login"
                ? "unavailable_login"
                : "unauthorized",
          }
        );
      });
  };

  const ErrorHandlingMsg = (code) => {
    if (code === "auth/invalid-email") return "This email address is invalid";
    else if (code === "auth/email-already-exists")
      return "The email address provided already exists";
    else if (code === "auth/user-disabled")
      return "This account has been disabled";
    else if (code === "auth/user-not-found")
      return "Invalid email or password, please try again";
    else if (code === "auth/wrong-password")
      return "Invalid email or password, please try again";
    else if (code === "auth/email-already-in-use")
      return "This email address is already in use";
    else if (code === "auth/invalid-email")
      return "This email address is incorrect";
    else if (code === "auth/weak-password" || code === "auth/invalid-password")
      return "Please input a stronger password";
    else if (code === "auth/account-exists-with-different-credential")
      return "This account is associated with a different login provider";
    else if (code === "auth/popup-blocked")
      return "Please allow browser popups";
    else if (code === "unavailable_login")
      return "The website is currently under maintenance. Please try again later";
    else if (code === "unauthorized") return "Unauthorized";
    else if (code === "unavailable_register")
      return "Registration is currently unavailable. Please try again later";
    else if (code === "auth/popup-closed-by-user")
      return "The popup has been closed, please try again";
    else return "An error occurred, please try again later";
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const value = {
    user,
    EmailAuth,
    signOut,
    ErrorHandlingMsg,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
