import React from "react";

import {
  Stack,
  Flex,
  Text,
  Button,
  Icon,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  Divider,
  FormHelperText,
} from "@chakra-ui/react";
import { FaEye, FaTrash } from "react-icons/fa";

import firebase from "../../utils/firebase";
import "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { Formik, Form, Field } from "formik";
import { LoadingMessage, ErrorMessage } from "../../components/card/Cards";

export default function TutorialsCard({ isOpen, onClose }: any) {
  const tutorialsRef = firebase
    .firestore()
    .collection("tutorials")
    .doc("tutorials");
  const [value, loading, error] = useDocumentData(tutorialsRef);
  const toast = useToast();
  const deleteIcon = async (index: number) => {
    if (value) {
      await firebase
        .firestore()
        .collection("tutorials")
        .doc("tutorials")
        .set(
          {
            videos: firebase.firestore.FieldValue.arrayRemove(
              value.videos[index]
            ),
          },
          { merge: true }
        )
        .catch(() => {
          toast({
            title: "Error Deleting",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        })
        .then((res: any) => {
          if (res)
            toast({
              title: "Tutorial Deleted",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
        });
    }
  };

  const viewLink = (index: any) => {
    if (value) {
      window.open(value.videos[index].url);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tutorials</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && <ErrorMessage />}
          {loading && <LoadingMessage />}
          {value &&
            value.videos.map((video: any, index: any) => {
              return (
                <Flex flexDir={"column"} alignContent={"center"} key={index}>
                  <Divider />
                  <Flex alignItems={"center"}>
                    <Box flex="1" textAlign="left" fontSize={"xl"} my={3}>
                      {video.title}
                    </Box>
                    <Button mx={1} onClick={() => viewLink(index)}>
                      <Icon as={FaEye} color={"white"} />
                    </Button>
                    <Button mx={1} onClick={() => deleteIcon(index)}>
                      <Icon as={FaTrash} color={"red.900"} />
                    </Button>
                  </Flex>
                </Flex>
              );
            })}
          {value && value.videos.length === 0 && (
            <Text textAlign={"center"} fontSize={"xl"} mt={4}>
              There are currently no tutorials
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <AddTutorialModal />
          <Button variant={"redBtn"} onClick={onClose}>
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function AddTutorialModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const validateURL = (value: any) => {
    let error;
    if (!value) {
      error = "URL is required";
    }
    return error;
  };

  const validateTitle = (value: any) => {
    let error;
    if (!value) {
      error = "Title is required";
    }
    return error;
  };

  return (
    <>
      <Button mx={3} onClick={onOpen}>
        New Tutorial
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Tutorial</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{ title: "", url: "", auth: "" }}
              onSubmit={async (values, actions) => {
                try {
                  await firebase
                    .firestore()
                    .collection("tutorials")
                    .doc("tutorials")
                    .set(
                      {
                        videos: firebase.firestore.FieldValue.arrayUnion({
                          url: values.url,
                          title: values.title,
                        }),
                      },
                      { merge: true }
                    )
                    .catch(() => {
                      toast({
                        title: "Error Adding Tutorial",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    })
                    .then((res: any) => {
                      if (res)
                        toast({
                          title: "Tutorial Added",
                          status: "success",
                          duration: 5000,
                          isClosable: true,
                        });
                    });
                  actions.setSubmitting(false);
                  onClose();
                } catch (error) {
                  actions.setErrors({ auth: error });
                  actions.setSubmitting(false);
                }
              }}
            >
              {({ errors, isSubmitting }) => (
                <Form style={{ width: "100%" }}>
                  <Flex align="center" w={"full"} flexDir={"column"}>
                    <Field name="title" validate={validateTitle}>
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.title && form.touched.title}
                          mb={6}
                        >
                          <FormLabel htmlFor="title">Title</FormLabel>
                          <Input {...field} id="title" variant={"redInput"} />
                          <FormErrorMessage>
                            {form.errors.title}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="url" validate={validateURL}>
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.url && form.touched.url}
                          mb={6}
                        >
                          <FormLabel htmlFor="url">URL</FormLabel>
                          <Input {...field} id="url" variant={"redInput"} />
                          <FormHelperText>
                            MP4 File Type OR YouTube Embedded Link
                          </FormHelperText>
                          <FormErrorMessage>{form.errors.url}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    {errors.auth && (
                      <Text
                        align="center"
                        fontSize="sm"
                        mt="2"
                        color={"red.300"}
                      >
                        {errors.auth}
                      </Text>
                    )}
                    <Stack spacing={5} w={"full"} mt={8} mb={4}>
                      <HStack w={"full"}>
                        <Button
                          onClick={onClose}
                          isFullWidth
                          colorScheme={"gray"}
                        >
                          Cancel
                        </Button>
                        <Button
                          isFullWidth
                          variant={"redBtn"}
                          isLoading={isSubmitting}
                          type="submit"
                        >
                          Add
                        </Button>
                      </HStack>
                    </Stack>
                  </Flex>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
