import React, { useEffect, useState } from "react";

import {
  Stack,
  Flex,
  Text,
  Button,
  Icon,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
  AspectRatio,
  Divider,
  FormHelperText,
  Textarea,
  Select,
  chakra,
} from "@chakra-ui/react";
import { FaEye, FaImage, FaImages, FaTrash, FaVideo } from "react-icons/fa";
import { useAuth } from "../../components/auth/useAuth";
import "firebase/firestore";

import { Formik, Form, Field } from "formik";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { LoadingMessage } from "../../components/card/Cards";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function NotificationsCard({ isOpen, onClose }: any) {
  const [Notifications, setNotifications] = useState<any>([]);
  const toast = useToast();
  const { user } = useAuth();
  const getNotifications = async () => {
    fetch("https://africasking.com/launcher/api/CRUD/notifications", {
      method: "GET",
      mode: "cors",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 1) {
            setNotifications(result.data);
          } else {
            toast({
              title: result.data,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        },
        (error) => {
          toast({
            title: "Error Occurred",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      );
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const deleteIcon = async (index: number) => {
    if (Notifications) {
      var urlencoded = new URLSearchParams();
      urlencoded.append("id", Notifications[index].id);
      urlencoded.append("uid", user.uid);
      fetch("https://africasking.com/launcher/api/CRUD/notifications", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlencoded,
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.status === 1) {
              toast({
                title: "Notification Deleted",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
              const reducedArr = [...Notifications];

              reducedArr.splice(index, 1);
              setNotifications(reducedArr);
            } else {
              toast({
                title: result.data,
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
          },
          (error) => {
            toast({
              title: "Error Occurred",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        );
    }
  };
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) {
      toast({
        title: "Notification did not move!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    var urlencoded = new URLSearchParams();
    urlencoded.append("move", result.draggableId);
    urlencoded.append("destination", result.destination.index + 1);
    urlencoded.append("uid", user.uid);

    fetch("https://africasking.com/launcher/api/CRUD/notifications", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 1) {
            toast({
              title: "Notification Moves",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setNotifications(result.data);
          } else {
            toast({
              title: result.data,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        },
        (error) => {
          toast({
            title: "Error Occurred",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"xl"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Notifications</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!Notifications && <LoadingMessage />}

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <ul
                  className="characters"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {Notifications.map((notification: any, index: any) => {
                    return (
                      <Draggable
                        key={notification.id}
                        draggableId={String(notification.id)}
                        index={index}
                      >
                        {(provided) => (
                          <Flex
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            flexDir={"column"}
                            alignContent={"center"}
                            key={index}
                          >
                            <Divider />
                            <Flex alignItems={"center"}>
                              <Box
                                flex="1"
                                textAlign="left"
                                fontSize={"xl"}
                                my={3}
                              >
                                {notification.video && (
                                  <Icon as={FaVideo} mr={2} />
                                )}
                                {notification.images &&
                                  notification.images.length === 1 && (
                                    <Icon as={FaImage} mr={2} />
                                  )}
                                {notification.images &&
                                  notification.images.length > 1 && (
                                    <Icon as={FaImages} mr={2} />
                                  )}
                                {notification.message.replace(
                                  /(.{20})..+/,
                                  "$1…"
                                )}
                              </Box>
                              <ViewNotificationsModal
                                notification={notification}
                                key={index}
                              />
                              <Button mx={1} onClick={() => deleteIcon(index)}>
                                <Icon as={FaTrash} color={"red.900"} />
                              </Button>
                            </Flex>
                          </Flex>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          {Notifications && Notifications.length === 0 && (
            <Text textAlign={"center"} fontSize={"xl"} mt={4}>
              There are currently no notifications
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <AddNotificationModal getNotifications={getNotifications} />
          <Button variant={"redBtn"} onClick={onClose}>
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ViewNotificationsModal({ notification }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button mx={1} onClick={onOpen}>
        <Icon as={FaEye} color={"white"} />
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Notification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex
              flexDir={"column"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Box flex="1" textAlign="left" fontSize={"xl"} my={3} w={"full"}>
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
                  {notification.images && (
                    <Carousel>
                      {notification.images.map((res: any, index: any) => (
                        <div key={index}>
                          <img src={res} alt={"notification"} />
                        </div>
                      ))}
                    </Carousel>
                  )}
                  {notification.video && (
                    <AspectRatio ratio={16 / 9}>
                      <iframe
                        title="Notification Video"
                        src={notification.video}
                        allowFullScreen
                      />
                    </AspectRatio>
                  )}

                  <Box p="6">
                    <Box
                      mt="1"
                      fontWeight="semibold"
                      as="h4"
                      lineHeight="tight"
                      isTruncated
                    >
                      {notification.message}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant={"redBtn"} onClick={onClose}>
              Return
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function AddNotificationModal({ getNotifications }: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { user } = useAuth();

  const validateVideo = (value: any) => {
    let error;
    if (!value) {
      error = "Video is required";
    }
    return error;
  };
  const validateImages = (value: any) => {
    let error;
    if (!value) {
      error = "Images is required";
    }
    return error;
  };

  const validateMessage = (value: any) => {
    let error;
    if (!value) {
      error = "Message is required";
    }
    return error;
  };

  return (
    <>
      <Button mx={3} onClick={onOpen}>
        New Notification
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Notification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                message: "",
                type: "",
                video: "",
                images: "",
                auth: "",
              }}
              onSubmit={async (values, actions) => {
                var urlencoded = new URLSearchParams();
                urlencoded.append("message", values.message);
                urlencoded.append("uid", user.uid);
                if (values.images) {
                  urlencoded.append("images", values.images);
                } else if (values.video) {
                  urlencoded.append("video", values.video);
                }

                try {
                  fetch(
                    "https://africasking.com/launcher/api/CRUD/notifications",
                    {
                      method: "POST",
                      mode: "cors",
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                      body: urlencoded,
                    }
                  )
                    .then((res) => res.json())
                    .then(
                      (result) => {
                        if (result.status === 1) {
                          toast({
                            title: "Notification Added",
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                          });
                          actions.setSubmitting(false);
                          getNotifications();
                          onClose();
                        } else {
                          toast({
                            title: result.data,
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                          });
                          actions.setSubmitting(false);
                        }
                      },
                      (error) => {
                        toast({
                          title: "Error Occurred",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                        actions.setSubmitting(false);
                      }
                    );
                } catch (error) {
                  actions.setErrors({ auth: error });
                  actions.setSubmitting(false);
                }
              }}
            >
              {({ errors, isSubmitting, values }) => (
                <Form style={{ width: "100%" }}>
                  <Flex align="center" w={"full"} flexDir={"column"}>
                    <Field name="type">
                      {({ field }: any) => (
                        <FormControl mb={6}>
                          <FormLabel htmlFor="message">
                            Notification Type
                          </FormLabel>

                          <Select {...field} id="type" variant={"redSelect"}>
                            <option value="message">Message</option>
                            <option value="images">Images</option>
                            <option value="video">Video</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="message" validate={validateMessage}>
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.message && form.touched.message
                          }
                          mb={6}
                        >
                          <FormLabel htmlFor="message">Message</FormLabel>
                          <Textarea
                            {...field}
                            id="message"
                            variant={"redTextarea"}
                          />
                          <FormErrorMessage>
                            {form.errors.message}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    {values.type === "video" && (
                      <Field name="video" validate={validateVideo}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.video && form.touched.video}
                            mb={6}
                          >
                            <FormLabel htmlFor="video">Video</FormLabel>
                            <Input {...field} id="video" variant={"redInput"} />
                            <FormHelperText>MP4 File Type</FormHelperText>

                            <FormErrorMessage>
                              {form.errors.video}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    )}
                    {values.type === "images" && (
                      <Field name="images" validate={validateImages}>
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.images && form.touched.images
                            }
                            mb={6}
                          >
                            <FormLabel htmlFor="images">Images</FormLabel>
                            <Input
                              {...field}
                              id="images"
                              variant={"redInput"}
                            />
                            <FormHelperText>Comma Separated</FormHelperText>
                            <FormErrorMessage>
                              {form.errors.images}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    )}
                    {errors.auth && (
                      <Text
                        align="center"
                        fontSize="sm"
                        mt="2"
                        color={"red.300"}
                      >
                        {errors.auth}
                      </Text>
                    )}
                    <Stack spacing={5} w={"full"} mt={8} mb={4}>
                      <HStack w={"full"}>
                        <Button
                          onClick={onClose}
                          isFullWidth
                          colorScheme={"gray"}
                        >
                          Cancel
                        </Button>
                        <Button
                          isFullWidth
                          variant={"redBtn"}
                          isLoading={isSubmitting}
                          type="submit"
                        >
                          Add
                        </Button>
                      </HStack>
                    </Stack>
                  </Flex>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
