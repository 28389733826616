import React from "react";

import { Stack, Flex, Button, Grid, Avatar } from "@chakra-ui/react";
import {
  FaBell,
  FaKey,
  FaMoneyBillWave,
  FaReceipt,
  FaTv,
  FaUserPlus,
  FaUsers,
  FaVideo,
} from "react-icons/fa";
import { useAuth } from "../components/auth/useAuth";

import { SquareCards } from "../components/card/Cards";

import NotificationsCard from "../components/admin/Notifications";
import TutorialsCard from "../components/admin/Tutorials";
import GetCodesCard from "../components/admin/Codes";
import UnapprovedCard from "../components/admin/Unapproved";
import PayoutsRequestsCard from "../components/admin/PayoutRequests";
import Resellers from "../components/admin/Resellers";
import Payouts from "../components/admin/Payouts";

export default function Dashboard(props: any) {
  const { signOut } = useAuth();

  return (
    <Flex
      w={"full"}
      minH={"100vh"}
      flexDir={"column"}
      bg={"gray.800"}
      overflow={"auto"}
    >
      <Flex
        color={"white"}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={"gray.900"}
        align={"center"}
        shadow={"md"}
      >
        <Flex flex={{ base: 1 }} justify="start">
          <Avatar src={"./images/zebra.png"} mr={2} p={1} bg={"white"} />
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Button fontSize={"sm"} variant={"redBtn"} onClick={() => signOut()}>
            Sign Out
          </Button>
        </Stack>
      </Flex>
      <Flex
        flexDir={"column"}
        px={{ base: 2, sm: 5 }}
        align={"center"}
        alignItems={"center"}
        pt={10}
        justifyContent={"center"}
        h={"full"}
        mb={"5vh"}
      >
        <Grid
          gridAutoRows={"1fr"}
          templateColumns={{
            base: "repeat(2, 0.5fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={{ base: "1rem", sm: "2rem" }}
          w={"full"}
          alignItems={"center"}
          h={"full"}
          justifyContent={"center"}
        >
          <SquareCards
            icon={FaUsers}
            text={"Users"}
            Section={Payouts}
            redirect="/users"
          />
          <SquareCards
            icon={FaTv}
            text={"Lines"}
            Section={Payouts}
            redirect="/lines"
          />
          <SquareCards
            icon={FaBell}
            text={"Notifications"}
            Section={NotificationsCard}
          />
          <SquareCards icon={FaKey} text={"Codes"} Section={GetCodesCard} />

          <SquareCards
            icon={FaVideo}
            text={"Tutorials"}
            Section={TutorialsCard}
          />
          <SquareCards icon={FaUsers} text={"Resellers"} Section={Resellers} />
          <SquareCards
            icon={FaUserPlus}
            text={"Unapproved"}
            Section={UnapprovedCard}
          />
          <SquareCards
            icon={FaReceipt}
            text={"Payout Request"}
            Section={PayoutsRequestsCard}
          />
          <SquareCards
            icon={FaMoneyBillWave}
            text={"Payouts"}
            Section={Payouts}
          />
        </Grid>
      </Flex>
    </Flex>
  );
}
