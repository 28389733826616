import React from "react";

import { Flex, Text, Icon, Spinner, useDisclosure } from "@chakra-ui/react";
import { FaExclamationTriangle } from "react-icons/fa";
import { Link } from "react-router-dom";

export const ErrorMessage = () => (
  <Flex flexDir={"column"} alignContent={"center"} alignItems={"center"}>
    <Icon as={FaExclamationTriangle} fontSize={"8rem"} color={"red.500"} />
    <Text textAlign={"center"} fontSize={"xl"} mt={4}>
      An error occurred, please try again later.
      <br />
      If this issue persists please contact us
    </Text>
  </Flex>
);

export const LoadingMessage = () => (
  <Flex flexDir={"column"} alignContent={"center"} alignItems={"center"}>
    <Spinner
      thickness="8px"
      speed="0.65s"
      emptyColor="gray.200"
      color="red.900"
      size="2xl"
    />
    <Text textAlign={"center"} fontSize={"xl"} mt={4}>
      Please Wait
    </Text>
  </Flex>
);

export const SquareCards = ({
  icon,
  text,
  sub,
  subColor,
  subSize = "2rem",
  Section,
  redirect = false,
  msg,
}: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        bg={"gray.700"}
        w="full"
        textAlign={"center"}
        pos={"relative"}
        rounded={"25%"}
        shadow={"md"}
        align={"center"}
        justifyContent={"center"}
        flexDir={"column"}
        h={"full"}
        maxW={"25rem"}
        py={5}
        maxH={{ base: "12rem", sm: "25rem" }}
        mx={"auto"}
        _hover={{
          bg: "gray.600",
          cursor: "pointer",
          shadow: "xl",
        }}
        onClick={() => !redirect && onOpen()}
        as={redirect ? Link : Flex}
        to={redirect ? redirect : <></>}
      >
        <Icon
          as={icon}
          fontSize={{ base: "4rem", sm: "10rem", md: "8rem" }}
          flex={1}
          pb={{ base: 1, sm: 5, md: 8 }}
          pt={{ base: 1, sm: 5 }}
          mb={sub ? 0 : 10}
        />
        {sub && (
          <Text
            fontSize={
              subSize === "2rem"
                ? { base: "1rem", sm: "2rem" }
                : { base: "1rem", sm: subSize }
            }
            color={subColor}
          >
            {sub}
          </Text>
        )}
        <Text fontSize={{ base: "1.5rem", sm: "3rem" }} mt={"auto"}>
          {text}
        </Text>
      </Flex>
      {isOpen && <Section isOpen={isOpen} onClose={onClose} msg={msg} />}
    </>
  );
};
