import * as React from "react";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import PrivateRoute from "./components/auth/privateRoute";
import { Switch, Route, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Users from "./pages/Users";
import Lines from "./pages/Lines";

import { ProvideAuth } from "./components/auth/useAuth";
import theme from "./utils/theme";

export const App = () => {
  const location = useLocation();

  return (
    <ChakraProvider theme={theme}>
      <ProvideAuth>
        <Flex minH={"100vh"} minW={"100vw"} maxW={"100vw"}>
          <Switch location={location} key={location.key}>
            <Route exact path="/" component={Login} />
            <PrivateRoute path="/admin" component={Admin} />
            <PrivateRoute path="/users" component={Users} />
            <PrivateRoute path="/lines" component={Lines} />
          </Switch>
        </Flex>
      </ProvideAuth>
    </ChakraProvider>
  );
};
