import React from "react";

import {
  Flex,
  Text,
  Button,
  Icon,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { FaCheck, FaTrash } from "react-icons/fa";

import firebase from "../../utils/firebase";
import "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuth } from "../auth/useAuth";

import TimeAgo from "react-timeago";
import { LoadingMessage, ErrorMessage } from "../../components/card/Cards";

export default function UnapprovedCard({ isOpen, onClose }: any) {
  const { user } = useAuth();
  const resellersRef = firebase
    .firestore()
    .collection("unapproved_resellers")
    .where("status", "!=", "declined");
  const [values, loading, error] = useCollectionData(resellersRef, {
    idField: "uid",
  });
  const toast = useToast();

  const deleteRequest = async (uid: string) => {
    if (uid) {
      fetch(process.env.REACT_APP_API_URL + "/reseller/account", {
        method: "DELETE",
        mode: "cors",
        body: JSON.stringify({
          uid: user.uid,
          reseller: uid,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          async (res) => {
            if (res.status === 1) {
              toast({
                title: "Request Deleted",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            } else {
              toast({
                title: "Error deleting the request",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
          },
          (error) => {
            toast({
              title: "Error deleting the request",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        );
    }
  };

  const approveRequest = async (uid: string) => {
    if (uid) {
      fetch(process.env.REACT_APP_API_URL + "/reseller/account", {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          uid: user.uid,
          reseller: uid,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          async (res) => {
            if (res.status === 1) {
              toast({
                title: "Request Approved",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            } else {
              toast({
                title: "Error approving the request",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
          },
          (error) => {
            toast({
              title: "Error approving the request",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"5xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Unapproved Resellers</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && <ErrorMessage />}
          {loading && <LoadingMessage />}
          {values && values.length > 0 && (
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Full Name</Th>
                  <Th>Email</Th>
                  <Th>Code</Th>
                  <Th>Created</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {values.map((reseller: any, index: any) => {
                  return (
                    <Tr key={index}>
                      <Td>{reseller.full_name}</Td>
                      <Td>{reseller.email}</Td>
                      <Td>{reseller.code}</Td>
                      <Td>
                        <TimeAgo date={reseller.created.seconds * 1000} />
                      </Td>
                      <Td>
                        <Flex>
                          <Button
                            mr={2}
                            onClick={() => deleteRequest(reseller.uid)}
                          >
                            <Icon as={FaTrash} color={"red.900"} />
                          </Button>
                          <Button onClick={() => approveRequest(reseller.uid)}>
                            <Icon as={FaCheck} color={"green.500"} />
                          </Button>
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
          {values && values.length === 0 && (
            <Text textAlign={"center"} fontSize={"xl"} mt={4}>
              There are currently no unapproved resellers
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant={"redBtn"} onClick={onClose}>
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
