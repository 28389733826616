import React from "react";

import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  Input,
  InputLeftAddon,
  VStack,
  InputRightAddon,
} from "@chakra-ui/react";

import firebase from "../../utils/firebase";
import "firebase/firestore";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";

import { LoadingMessage, ErrorMessage } from "../../components/card/Cards";
import Countdown from "react-countdown";

export default function GetCodesCard({ isOpen, onClose }: any) {
  const pricesRef = firebase
    .firestore()
    .collection("prices")
    .where("price", "<=", 5);
  const [values, loading, error] = useCollectionData(pricesRef, {
    idField: "uid",
  });
  const cronSettings = firebase.firestore().collection("settings").doc("cron");
  const [value] = useDocumentData(cronSettings);
  const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return <Text>Refresh the page to view the new codes</Text>;
    } else {
      return (
        <span>
          {days}:{String(hours).padStart(2, "0")}:
          {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
        </span>
      );
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Current Codes</ModalHeader>
        <ModalCloseButton />
        <ModalBody justify={"center"} align="center">
          {values && values?.length > 0 && (
            <VStack spacing={6} justify={"center"} align="center">
              <Text>
                The codes are resting in{" "}
                {value && (
                  <b>
                    <Countdown
                      renderer={renderer}
                      zeroPadTime={2}
                      date={
                        new Date(value.referral.seconds * 1000).getTime() +
                        24 * 60000 * 60 * 7
                      }
                    />
                  </b>
                )}
              </Text>
              {values.map((value) => {
                return (
                  <InputsCodes
                    name={value.uid.replaceAll("0", "")}
                    value={value.code}
                    price={value.price}
                  />
                );
              })}
              <Text fontSize={"sm"}>The codes reset ever week on Sunday</Text>
            </VStack>
          )}
          {((values && values.length === 0) || error) && <ErrorMessage />}
          {loading && <LoadingMessage />}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} isFullWidth variant={"redBtn"} mt={5}>
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function InputsCodes({ name, value, price }: any) {
  return (
    <InputGroup>
      <InputLeftAddon children={name} />
      <Input value={value} isReadOnly placeholder={name} variant={"redInput"} />
      <InputRightAddon>
        <Text>${price} USD</Text>
      </InputRightAddon>
    </InputGroup>
  );
}
