import React from "react";

import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
} from "@chakra-ui/react";

import firebase from "../../utils/firebase";
import "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import TimeAgo from "react-timeago";
import { LoadingMessage, ErrorMessage } from "../../components/card/Cards";

export default function UnapprovedCard({ isOpen, onClose }: any) {
  const resellersRef = firebase
    .firestore()
    .collection("payouts")
    .where("status", "==", "completed")
    .limit(10)
    .orderBy("created", "desc");
  const [values, loading, error] = useCollectionData(resellersRef);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"5xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Payouts</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && <ErrorMessage />}
          {loading && <LoadingMessage />}
          {values && values.length > 0 && (
            <Table variant="striped">
              <TableCaption>The last 10 payouts</TableCaption>
              <Thead>
                <Tr>
                  <Th>Full Name</Th>
                  <Th>Email</Th>
                  <Th>amount</Th>
                  <Th>Requested</Th>
                  <Th>Completed</Th>
                </Tr>
              </Thead>
              <Tbody>
                {values.map((payout: any, index: any) => {
                  return (
                    <Tr key={index}>
                      <Td>{payout.full_name}</Td>
                      <Td>{payout.email}</Td>
                      <Td>${payout.amount}</Td>
                      <Td>
                        <TimeAgo date={payout.created.seconds * 1000} />
                      </Td>
                      <Td>
                        <TimeAgo date={payout.completed?.seconds * 1000} />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
          {values && values.length === 0 && (
            <Text textAlign={"center"} fontSize={"xl"} mt={4}>
              There are currently no unapproved resellers
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant={"redBtn"} onClick={onClose}>
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
