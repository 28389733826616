import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  useColorModeValue,
  Text,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  Stack,
  FormControl,
  Input,
  FormLabel,
  Divider,
  Avatar,
} from "@chakra-ui/react";

import firebase from "../utils/firebase";
import "firebase/firestore";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";

import DataTable from "../utils/table";

import {
  FaArrowDown,
  FaArrowUp,
  FaCoins,
  FaCreditCard,
  FaInfinity,
  FaSearch,
  FaTv,
  FaUser,
} from "react-icons/fa";
import { useAuth } from "../components/auth/useAuth";
import { Formik, Form, Field } from "formik";
import { LoadingCard, ErrorCard } from "../utils/helpers";
import { Link } from "react-router-dom";

export default function AdminUsers(props: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openModal, setOpenModal] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [search, setSearch] = useState<any>(null);

  const [direction, setDirection] = useState<any>("desc");
  const [order, setOrder] = useState("created");
  const pageLimit = 12;
  const { signOut } = useAuth();

  let usersRef;
  if (search && search.result && search.field) {
    usersRef = firebase
      .firestore()
      .collection("users")
      .where(search.field, "==", search.result.toLowerCase());
  } else if (search && search.pagination === "next") {
    usersRef = firebase
      .firestore()
      .collection("users")
      .orderBy(order, direction)
      .startAfter(search.lastVisible)
      .limit(pageLimit);
  } else if (search && search.pagination === "back") {
    usersRef = firebase
      .firestore()
      .collection("users")
      .orderBy(order, direction)
      .startAt(search.back)
      .limit(pageLimit);
  } else {
    usersRef = firebase
      .firestore()
      .collection("users")
      .orderBy(order, direction)
      .limit(pageLimit);
  }

  const [values, loading, error] = useCollectionData(usersRef, {
    idField: "uid",
    refField: "ref",
  });

  return (
    <Flex
      w={"full"}
      minH={"100vh"}
      flexDir={"column"}
      bg={"gray.800"}
      overflow={"auto"}
    >
      <Flex
        color={"white"}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={"gray.900"}
        align={"center"}
        shadow={"md"}
      >
        <Flex flex={{ base: 1 }} justify="start">
          <Avatar
            src={"./images/zebra.png"}
            mr={2}
            p={1}
            bg={"white"}
            as={Link}
            to={"/admin"}
          />
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Button fontSize={"sm"} variant={"redBtn"} onClick={() => signOut()}>
            Sign Out
          </Button>
        </Stack>
      </Flex>
      <Flex
        flexDir={"column"}
        px={{ base: 2, sm: 5 }}
        pt={10}
        h={"full"}
        mb={"5vh"}
      >
        <Box
          w={"full"}
          bg={"gray.700"}
          rounded={"lg"}
          boxShadow={"md"}
          p={6}
          mt={10}
        >
          <Flex
            align={"center"}
            w={"full"}
            justify={"center"}
            flexDir={{ base: "column", md: "row" }}
          >
            <SearchBar setSearch={setSearch} />
            <FilterUI
              order={order}
              direction={direction}
              setDirection={setDirection}
              setOrder={setOrder}
              setSearch={setSearch}
            />
          </Flex>
        </Box>
        <Box
          w={"full"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"lg"}
          boxShadow={"lg"}
          p={6}
          mt={10}
        >
          {values && (
            <DataTable
              columns={columnHeaders(setOpenModal, setCurrentUser, onOpen)}
              data={values}
            />
          )}
          <Flex justifyContent={"center"} mt={6}>
            {values && search && search.counter ? (
              <Button
                mx={2}
                onClick={() => {
                  setSearch({
                    lastVisible: values.length
                      ? values[values.length - 1][order]
                      : search.firstVisible[search.counter - 1],
                    firstVisible: search.firstVisible,
                    back: search.firstVisible[search.counter - 1],
                    pagination: "back",
                    counter: search.counter - 1,
                  });
                }}
              >
                Back
              </Button>
            ) : (
              <></>
            )}
            {values && values.length > 0 && (
              <Button
                mx={2}
                onClick={() => {
                  setSearch({
                    lastVisible: values[values.length - 1][order],
                    firstVisible: search?.firstVisible
                      ? [...search?.firstVisible, values[0][order]]
                      : [values[0][order]],
                    pagination: "next",
                    counter: search?.counter ? search.counter + 1 : 1,
                  });
                }}
              >
                Next
              </Button>
            )}
          </Flex>
          {loading && <LoadingCard />}
          {error && <ErrorCard />}
          {error && <Text>{JSON.stringify(error.message)}</Text>}
          {openModal === "Details" && currentUser && (
            <ViewDetails data={currentUser} onClose={onClose} isOpen={isOpen} />
          )}
          {openModal === "Payments" && currentUser && (
            <ViewPayments uid={currentUser} onClose={onClose} isOpen={isOpen} />
          )}
          {openModal === "Line" && currentUser && (
            <ViewLine uid={currentUser} onClose={onClose} isOpen={isOpen} />
          )}
        </Box>
      </Flex>
    </Flex>
  );
}

const columnHeaders = (setOpenModal: any, setCurrentUser: any, onOpen: any) => [
  {
    Header: "Email",
    Cell: (row: any) => {
      const data = row.row.original;
      return <Text>{data.email}</Text>;
    },
  },
  {
    Header: "Date Joined",
    Cell: (row: any) => {
      const data = row.row.original;
      return (
        <Text>{new Date(data.created.seconds * 1000).toDateString()}</Text>
      );
    },
  },
  {
    Header: "Expiration",
    Cell: (row: any) => {
      const data = row.row.original;
      if (data.expiration_date && data.expiration_date.seconds)
        return (
          <Text>
            {new Date(data.expiration_date.seconds * 1000).toDateString()}
          </Text>
        );
      else if (data.expiration_date === "unlimited")
        return <Icon color={"orange.900"} as={FaInfinity} />;
      else return "-";
    },
  },
  {
    Header: "Subscription",
    Cell: (row: any) => {
      const data = row.row.original;
      return (
        <Text
          color={
            data.subscription === "active"
              ? "green.500"
              : data.subscription === "pending"
              ? "orange.400"
              : data.subscription === "error"
              ? "pink.400"
              : "red.500"
          }
        >
          {data.subscription === "active"
            ? "Active"
            : data.subscription === "pending"
            ? "Pending"
            : data.subscription === "error"
            ? "Error"
            : "Inactive"}
        </Text>
      );
    },
  },
  {
    Header: "Actions",
    Cell: (row: any) => {
      const data = row.row.original;
      return (
        <Flex>
          <IconButton
            icon={<FaUser />}
            aria-label="Details"
            mr={1}
            onClick={() => {
              setOpenModal("Details");
              setCurrentUser(data);
              onOpen();
            }}
          />
          <IconButton
            icon={<FaTv />}
            aria-label="Line"
            mr={1}
            onClick={() => {
              setOpenModal("Line");
              setCurrentUser(data.uid);
              onOpen();
            }}
          />
          <IconButton
            icon={<FaCreditCard />}
            aria-label="Payments"
            mr={1}
            onClick={() => {
              setOpenModal("Payments");
              setCurrentUser(data.uid);
              onOpen();
            }}
          />
        </Flex>
      );
    },
  },
];

function ViewPayments({ uid, onClose, isOpen }: any) {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={"outside"}
      isCentered
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaCoins} mr={2} />
          Payments
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PaymentsView uid={uid} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="redBtn">
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ViewLine({ uid, onClose, isOpen }: any) {
  const accountRef = firebase
    .firestore()
    .collection("accounts")
    .where("user", "==", uid)
    .limit(1);

  const [values, loading, error] = useCollectionData(accountRef, {
    idField: "uid",
  });

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaTv} mr={2} />
          Line Credentials
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {values && values[0]?.username && (
            <Flex flexDir={"column"}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Username</FormLabel>
                  <Input
                    isReadOnly
                    defaultValue={values[0].username}
                    variant={"redInput"}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input
                    isReadOnly
                    defaultValue={values[0].password}
                    variant={"redInput"}
                  />
                </FormControl>
                <Text fontSize={"sm"} textAlign={"center"}>
                  {values[0].uid}
                </Text>
              </Stack>
              <Button isFullWidth onClick={onClose} variant="redBtn" my={6}>
                Return
              </Button>
            </Flex>
          )}
          {values && !values[0]?.username && (
            <Flex flexDir={"column"}>
              <Text textAlign={"center"}>User has no line assigned</Text>
              <Button isFullWidth onClick={onClose} variant="redBtn" my={6}>
                Return
              </Button>
            </Flex>
          )}
          {loading && <LoadingCard />}
          {error && <ErrorCard />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function ViewDetails({ data, onClose, isOpen }: any) {
  const resellerRef = firebase
    .firestore()
    .collection("resellers")
    .doc(data.reseller);

  const [value, loading, error] = useDocumentData(resellerRef, {
    idField: "uid",
  });
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"lg"}>
      <ModalOverlay />
      <ModalContent bg={useColorModeValue("gray.50", "gray.800")}>
        <ModalHeader>
          <Icon as={FaTv} mr={2} />
          Account Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {data && value && (
            <Flex flexDir={"column"}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    isReadOnly
                    defaultValue={data.email}
                    variant={"redInput"}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Reseller</FormLabel>
                  <Input
                    isReadOnly
                    defaultValue={
                      value.full_name || value.uid.replaceAll("0000", "")
                    }
                    variant={"redInput"}
                  />
                </FormControl>
                {data.stripeID && (
                  <FormControl>
                    <FormLabel>Stripe ID</FormLabel>
                    <Input
                      isReadOnly
                      defaultValue={data.stripeID}
                      variant={"redInput"}
                    />
                  </FormControl>
                )}
                {data.subscriptionID && (
                  <FormControl>
                    <FormLabel>Subscription ID</FormLabel>
                    <Input
                      isReadOnly
                      defaultValue={data.subscriptionID}
                      variant={"redInput"}
                    />
                  </FormControl>
                )}
                {data.username && (
                  <FormControl>
                    <FormLabel>Desired Username</FormLabel>
                    <Input
                      isReadOnly
                      defaultValue={data.username}
                      variant={"redInput"}
                    />
                  </FormControl>
                )}
                <Text fontSize={"sm"} textAlign={"center"}>
                  {data.uid}
                </Text>
              </Stack>
              <Button isFullWidth onClick={onClose} variant="redBtn" my={6}>
                Return
              </Button>
            </Flex>
          )}
          {loading && <LoadingCard />}
          {error && <ErrorCard />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function SearchBar(props: any) {
  return (
    <Formik
      initialValues={{ search: "" }}
      onSubmit={async (values: any, actions: any) => {
        props.setSearch({ field: "email", result: values.search });
        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack
            spacing={1}
            direction={{ base: "column", md: "row" }}
            alignItems={"center"}
          >
            <Stack direction={"row"}>
              <Field name="search">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.search && form.touched.search}
                  >
                    <Input
                      {...field}
                      id="search"
                      placeholder="Search"
                      w={{ base: "full", md: "25vw" }}
                      variant={"redInput"}
                    />
                  </FormControl>
                )}
              </Field>
              <Button isLoading={isSubmitting} type="submit">
                <Icon as={FaSearch} />
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

function FilterUI(props: any) {
  const buttons = [
    {
      filter: "email",
      name: "Email",
    },
    {
      filter: "created",
      name: "Joined",
    },
    {
      filter: "expiration_date",
      name: "Expiration ",
    },
    {
      filter: "subscription",
      name: "Subscription",
    },
  ];

  return (
    <Flex ml={{ base: 0, md: 2 }} flexDir={"row"} flexWrap={"wrap"}>
      <Divider my={4} display={{ base: "block", md: "none" }} />
      {buttons.map((item, index) => (
        <Button
          my={{ base: 1, md: 0 }}
          key={index}
          mx={1}
          onClick={() => {
            props.setSearch(null);
            props.setDirection(
              item.filter !== props.order
                ? "asc"
                : props.direction === "desc"
                ? "asc"
                : "desc"
            );
            props.setOrder(item.filter);
          }}
        >
          {item.name}
          {props.order === item.filter && props.direction === "asc" && (
            <Icon as={FaArrowUp} ml={1} />
          )}
          {props.order === item.filter && props.direction === "desc" && (
            <Icon as={FaArrowDown} ml={1} />
          )}
        </Button>
      ))}
    </Flex>
  );
}

const PaymentsView = ({ uid }: any) => {
  const paymentsRef = firebase
    .firestore()
    .collection("payments")
    .where("user_uid", "==", uid);

  const [values, loading, error] = useCollectionData(paymentsRef);
  return (
    <>
      {values && values.length > 0 && (
        <DataTable
          columns={[
            {
              Header: "Amount",
              accessor: "amount",
            },
            {
              Header: "Date",
              accessor: "created",
              Cell: (row: any) => {
                const data = row.row.original;
                return (
                  <Text>
                    {new Date(data.created.seconds * 1000).toDateString()}
                  </Text>
                );
              },
            },
          ]}
          data={values}
        />
      )}
      {values && values.length === 0 && (
        <Text textAlign={"center"}>This user does not have any payments</Text>
      )}
      {loading && <LoadingCard />}
      {error && <ErrorCard />}
      {error && <Text>{JSON.stringify(error.message)}</Text>}
    </>
  );
};
