import React, { useState, useEffect } from "react";

import {
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
} from "@chakra-ui/react";

import firebase from "../../utils/firebase";
import "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import TimeAgo from "react-timeago";

import { LoadingMessage, ErrorMessage } from "../../components/card/Cards";
const prices: any = {
  price_1J4HM2FwZBmNe2giKsdwgld1: "7",
  price_1KDqaRFwZBmNe2gigSkpt3s9: "6",
};
export default function PayoutsRequestsCard({ isOpen, onClose }: any) {
  const [balance, setBalance] = useState(0);

  const resellersRef = firebase
    .firestore()
    .collection("resellers")
    .orderBy("balance", "desc")
    .orderBy("subscriptions", "desc");

  const [values, loading, error] = useCollectionData(resellersRef, {
    idField: "uid",
  });

  useEffect(() => {
    if (values) {
      var count = 0;
      values.forEach((value) => {
        if (value.balance && !value.uid.startsWith("0000")) {
          count += value.balance;
        }
      });
      setBalance(count);
    }
  }, [values]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={"6xl"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Resellers</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {error && <ErrorMessage />}
          {loading && <LoadingMessage />}
          {values && values.length > 0 && (
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Full Name</Th>
                  <Th>Email</Th>
                  <Th>Code/Price</Th>
                  <Th>Balance</Th>
                  <Th>Users</Th>
                  <Th>Created</Th>
                </Tr>
              </Thead>
              <Tbody>
                {values.map((reseller: any, index: any) => {
                  if (!reseller.created || reseller.uid.startsWith("0000")) {
                    return <React.Fragment key={index}></React.Fragment>;
                  }
                  const created =
                    typeof reseller.created === "string"
                      ? new Date(reseller.created)
                      : reseller.created.seconds * 1000;
                  return (
                    <Tr key={index}>
                      <Td>{reseller.full_name}</Td>
                      <Td>{reseller.email}</Td>
                      <Td>
                        {reseller.code} / ${prices[reseller.priceID]}
                      </Td>
                      <Td color={reseller.balance > 0 ? "green.500" : ""}>
                        ${reseller.balance}
                      </Td>
                      <Td color={reseller.subscriptions > 0 ? "green.500" : ""}>
                        {reseller.subscriptions}
                      </Td>
                      <Td>
                        <TimeAgo date={created} />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
          {values && values.length === 0 && (
            <Text textAlign={"center"} fontSize={"xl"} mt={4}>
              There are currently no resellers requests
            </Text>
          )}
        </ModalBody>
        <ModalFooter flexWrap={"wrap"} flexDir={{ base: "column", xl: "row" }}>
          {values && values.length > 0 && (
            <Flex mr={{ base: 0, xl: "auto" }} mb={2}>
              <Badge>Resellers: {values.length}</Badge>
              <Badge ml={"5"}>Balance Owed: ${balance}</Badge>
            </Flex>
          )}
          <Button variant={"redBtn"} onClick={onClose}>
            Return
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
