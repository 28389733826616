import React from "react";
import { Flex, Text, Icon, Spinner } from "@chakra-ui/react";

import { FaExclamationTriangle } from "react-icons/fa";

export const LoadingCard = () => {
  return (
    <Flex flexDir={"column"} align={"center"}>
      <Spinner
        thickness="8px"
        speed="0.65s"
        emptyColor="gray.200"
        color="red.900"
        size="2xl"
        my={6}
      />
      <Text fontSize={"xl"} textAlign={"center"}>
        Please Wait
      </Text>
    </Flex>
  );
};

export const ErrorCard = () => {
  return (
    <Flex flexDir={"column"} align={"center"}>
      <Icon
        as={FaExclamationTriangle}
        fontSize={"8rem"}
        my={4}
        color={"red.500"}
      />
      <Text fontSize={"xl"} textAlign={"center"}>
        Error occurred
      </Text>
    </Flex>
  );
};

export const capitalizeFirstLetter = (string: any) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
